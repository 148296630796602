<template>
    <div>
        <Header></Header>
        <div class="pric">
            <h2 class="title">SweepsRules</h2>
            <p><strong>DATED: 29/3/2024</strong></p>
            <p><strong>A ELIGIBILITY</strong></p>
            <p>a.The following definitions apply to these Sweeps Rules:</p>
            <p>· Eligible Participants means legal residents of the Permitted Territories;</p>
            <p>· Permitted Territories means the United States (other than the States of Idaho and Washington), and Canada (other than the province of Quebec);</p>
            <p>· Participant means a person who participates in Promotional Play;</p>
            <p>· Promotional Play means Game’s sweepstakes promotions.</p>
            <p>· Promotional Play is only open to Eligible Participants, who are at least eighteen (18) years old or the age of majority in their jurisdiction (whichever occurs later) at the time of entry. PARTICIPATION IS VOID WHERE PROHIBITED BY LAW.</p>
            <p></p>
            <p>b.The Sweepstakes is subject to all applicable federal, state, provincial, territorial and local laws and regulations. It is the sole responsibility of a Participant to determine whether the Sweepstakes is legal and compliant with all regulations in the jurisdiction in which the Participant resides.</p>
            <p>c.Participation constitutes the Participant’s full and unconditional agreement to these Sweeps Rules and Sponsor’s/Promoter’s decisions, which are final and binding in all matters related to the Sweepstakes. </p>
            <p>d.Winning a prize is contingent upon fulfilling all requirements set out in these Sweeps Rules.</p>
            <p></p>
            <p></p>
            <p><strong>B.SPONSOR</strong></p>
            <p>a.The Sponsor of the Sweepstakes is One Game Dev.</p>
            <p>b.NO PURCHASE OR PAYMENT NECESSARY TO PARTICIPATE. A PURCHASE OR PAYMENT OF ANY KIND WILL NOT INCREASE A PARTICIPANT’S CHANCES OF WINNING.</p>
            <p>c.Game gives away sweepstakes entries referred to as “Sweeps Coins” as set out in these Sweeps Rules. Sweeps Coins can be used to participate in games of Promotional Play for a chance to win further Sweeps Coins which are redeemable for real prizes.</p>
            <p></p>
            <p></p>
            <p><strong>C.HOW TO COLLECT SWEEPS COINS</strong></p>
            <p>To Participate, you must have an active Customer Account.</p>
            <p>b.Once they have accessed Game, there are two potential ways for an Eligible Participant to collect Sweeps Coins:</p>
            <p>· Receive Sweeps Coins as a free bonus when purchasing Gold Coins. Each Participant may receive Sweeps Coins as a bonus upon the purchase of specifically marked packs of Gold Coins. Gold Coins can be used for social game play in Game. Gold Coins cannot be redeemed for prizes. The number of Sweeps Coins a Participant will receive as a bonus for each relevant Gold Coin purchase will be stated in the website purchase window. All purchases of Gold Coins are final and no refunds will be given.</p>
            <p>· Receive Sweeps Coins by logging into your account. Participants can receive free Sweeps Coins on a daily basis by taking the following steps (Daily Bonus):</p>
            <p>&nbsp;&nbsp;&nbsp;&nbsp;signing into their Customer Account;</p>
            <p>&nbsp;&nbsp;&nbsp;&nbsp;clicking on the “Get Coins” button in the lobby page;</p>
            <p>c.The amount of Sweeps Coins a Participant has will be displayed in their Customer Account on the website. The amount of Sweeps Coins to be allocated to Participants can be changed at any time by the Sponsor/Promoter in its sole discretion.</p>
            <p>d.Sponsor/Promoter is not responsible for lost, late, incomplete, invalid, unintelligible or misdirected Sweeps Coins requests or allocations. </p>
            <p>e.In the event of a dispute as to any registration of a Customer Account, the authorized account holder of the email address used to register the Customer Account will be deemed to be the Participant. The “authorized account holder” is the natural person assigned the email address by an internet access provider, online service provider or other organization responsible for assigning email addresses for the domain associated with the submitted address. </p>
            <p>f.Use of any automated or other system(s) to Participate, to acquire Sweeps Coins or play the games is prohibited and will result in disqualification and loss of eligibility to Participate in the games. </p>
            <p>g.Sweeps Coins are only valid for ninety (90) days from the date a Participant last logged on to their Customer Account and will then automatically expire. </p>
            <p>h.Sweeps Coins may also be forfeited if a Participant’s Customer Account is closed for any reason, or at the Sponsor/Promoter’s discretion.</p>
            <p></p>
            <p></p>
            <p><strong>D.USING SWEEPS COINS TO PLAY GAMES</strong></p>
            <p>a.Participants with Sweeps Coins can use those Sweeps Coins to play games within Game for a chance to win additional Sweeps Coins. Sweeps Coins won through game play can be redeemed for real prizes. </p>
            <p>b.Within Game there are different games. The amount of Sweeps Coins required to play each game will be detailed next to the game. A Participant may be able to increase the amount of Sweeps Coins they play the game with so they can increase their potential winning eligibility. </p>
            <p>c.Only games played with Sweeps Coins provide the opportunity to redeem winnings for real prizes. </p>
            <p>d.Each Sweeps Coin that has been won through game play can be redeemed for a prize with value being equivalent to US$1.</p>
            <p>e.Sponsor’s/Promoter’s decisions as to the administration and operation of the Sweepstakes, the game and the amount of winnings are final and binding.</p>
            <p>f.Unless Sponsor/Promoter requires otherwise, any Sweeps Coins allocated to a Participant are required to be played once before they are eligible to be redeemed for prizes. Sponsor/Promoter may, in its sole discretion,require that any Sweeps Coins allocated to a Participant must be played a greater number of times (not exceeding 20) in any combination of Sweeps Coins games before they are eligible to be redeemed for prizes.</p>
            <p></p>
            <p></p>
            <p><strong>E.VERIFICATION AND CONFIRMATION OF POTENTIAL WINNERS</strong></p>
            <p>a.POTENTIAL SWEEPSTAKES WINNERS ARE SUBJECT TO VERIFICATION BY SPONSOR/PROMOTER (IN ANY MANNER IT MAY CHOOSE) AND THE DECISIONS OF SPONSOR/PROMOTER ARE FINAL AND BINDING IN ALL MATTERS RELATED TO THE SWEEPSTAKES. A PARTICIPANT IS NOT A WINNER OF ANY PRIZE, EVEN IF THE</p>
            <p>b.ONLINE SCREEN INDICATES THEY ARE, UNLESS AND UNTIL THE PARTICIPANT’S ELIGIBILITY AND THE POTENTIAL WINNING PLAY HAS BEEN VERIFIED AND THE PARTICIPANT HAS FULLY COMPLIED WITH THESE SWEEPS RULES AND BEEN NOTIFIED THAT VERIFICATION IS COMPLETE. SPONSOR/PROMOTER WILL NOT ACCEPT SCREEN SHOTS OR OTHER PURPORTED EVIDENCE OF WINNING IN LIEU OF ITS VALIDATION PROCESS.</p>
            <p>c.Potential prize winners must comply with these Sweeps Rules and winning is contingent upon fulfilling all requirements. </p>
            <p>d.In the event that a potential winner of a Sweepstakes prize is disqualified for any reason, Sponsor/Promoter may, in its sole discretion, reseed the prize back into the Sweepstakes. </p>
            <p></p>
            <p></p>
            <p><strong>F.PRIZES</strong></p>
            <p>a.A Participant’s Sweeps Coins balance is shown in the Participant’s Customer Account.</p>
            <p>b.Where a Participant has chosen to redeem prizes for cash, the cash payment will be paid in USD to the financial account or online wallet from which you purchased Gold Coins, or if this is not technically possible, then to an alternative bank account you nominate. Sponsor/Promoter reserves the right to require the use of the same payment method for redemption of prizes as was used to purchase Gold Coins, or a specific payment method at Sponsor/Promoter's discretion.</p>
            <p>c.Where a Participant has chosen to redeem prizes for gift cards, the gift cards will be allocated to the email address that the Participant has registered against their Customer Account.</p>
            <p>d.Sponsor/Promoter will only process a prize redemption if the redeemed value of the prize is US$50 or more (i.e. a minimum of 50 eligible Sweeps Coins). Consequently, a Participant’s Customer Account must have a balance of at least 50 Sweeps Coins before requesting a prize redemption.</p>
            <p>e.TO BE ELIGIBLE FOR A SWEEPS COINS PRIZE OR A SWEEPS COINS PRIZE REDEMPTION:</p>
            <p>f.A PARTICIPANT MUST BE AN ELIGBLE PARTICIPANT; AND </p>
            <p>g.THE PARTICIPANT’S DETAILS MUST MATCH THOSE OF THE PARTICIPANT’S CUSTOMER ACCOUNT.</p>
            <p>h.In New York and Florida, the maximum redemption value of a Sweeps Coins prize won on any one spin or play, via a Participant’s participation in the Promotional Play Games is US$5,000. Any prize valued in excess of US$5,000 willbe reduced to a maximum value of US$5,000.</p>
            <p>i.Sponsor/Promoter reserves the right, in its sole discretion, to limit a Participant’s redemption of Sweeps Coins to US$10,000 per day. No more than the stated number of prizes will be awarded.</p>
            <p>j.Sponsor/Promoter is not responsible for any taxes or fees associated with a prize redemption. Participants are responsible for all applicable taxes and fees associated with prize receipt and/or redemption.</p>
            <p>k.Sponsor/Promoter is not responsible for any foreign exchange transaction fees, charges or related costs that may be incurred as a result of, or in relation to, a prize redemption, including but not limited to any losses or additional costs arising from foreign exchange fluctuations.</p>
            <p>l.Sweeps Coins are not transferable and no substitution will be made except as provided herein at the Sponsor’s/Promoter’s sole discretion. Sponsor/Promoter reserves the right to substitute the listed prize of equal or greater value for any reason owing to circumstances outside Sponsor’s/Promoter’s reasonable control. </p>
            <p>m.Prize winners will be notified by private message or email.</p>
            <p></p>
            <p></p>
            <p><strong>G.ENTRY CONDITIONS AND RELEASE</strong></p>
            <p>a.By Participating, each Participant agrees to: </p>
            <p>· comply with and be bound by:</p>
            <p>· the T&{{`C`}}s;</p>
            <p>· these Sweeps Rules; and</p>
            <p>· the decisions of the Sponsor/Promoter which are binding and final;</p>
            <p>· release and hold harmless the Sponsor/Promoter and its parent, subsidiary, and affiliated companies, the prize suppliers and any other organizations responsible for sponsoring, fulfilling, administering, advertising or promoting the Sweepstakes, and all of their respective past and present officers, directors, employees, agents and representatives (collectively, the Released Parties) from and against any and all claims, expenses, and liability, including but not limited to negligence and damages of any kind to persons and property, including but not limited to invasion of privacy (under appropriation, intrusion, public disclosure of private facts, false light in the public eye or other legal theory), defamation, slander, libel, violation of right of publicity, infringement of trademark, copyright or other intellectual property rights, property damage, or death or personal injury arising out of or relating to a Participant’s entry, creation of an entry or submission of an entry, participation in the Sweepstakes, acceptance or use or misuse of prizes (including any travel or activity related thereto) and/or the broadcast, exploitation or use of entry; and</p>
            <p>· indemnify, defend and hold harmless the Sponsor/Promoter from and against any and all claims, expenses, and liabilities (including reasonable attorneys/legal fees) arising out of or relating to a Participant’s participation in the Sweepstakes and/or Participant’s acceptance, use or misuse of prizes.</p>
            <p></p>
            <p></p>
            <p><strong>H.PUBLICITY</strong></p>
            <p>a.Except where prohibited, participation in the Sweepstakes constitutes each Participant’s consent to Sponsor’s/Promoter’s and its agents’ use of Participant’s name, likeness, photograph, voice, opinions and/or hometown and state/province/territory for promotional purposes in any media, worldwide, without further payment, notice or consideration.</p>
            <p></p>
            <p></p>
            <p><strong>I.GENERAL CONDITIONS</strong></p>
            <p>a.Sponsor/Promoter reserves the right to cancel, suspend and/or modify the Sweepstakes or these Sweeps Rules, or any part of the Sweepstakes or these Sweeps Rules, with immediate effect owing to circumstances outside its reasonable control and only where circumstances make it unavoidable if any fraud, technical failures or any other factor beyond Sponsor’s/Promoter’s reasonable control impairs the integrity or proper functioning of the Sweepstakes, as determined by Sponsor/Promoter in its sole discretion.</p>
            <p>b.Sponsor/Promoter reserves the right in its sole discretion to disqualify any individual it finds to be tampering with the entry process or the operation of the Sweepstakes or to be acting in violation of these Sweeps Rules or any other promotion or in an unsportsmanlike or disruptive manner.</p>
            <p>c.Any attempt by any person to deliberately undermine the legitimate operation of the Sweepstakes may be a violation of criminal and civil law and, should such an attempt be made, Sponsor/Promoter reserves the right to seek damages from any such person to the fullest extent permitted by law. Sponsor’s/Promoter’s failure to enforce any term of these Sweeps Rules shall not constitute a waiver of that provision.</p>
            <p>d.In all other cases, the Sponsor/Promoter reserves the right to cancel, suspend and/or modify the Sweepstakes.</p>
            <p>e.Whenever we amend these Sweeps Rules in a way that would limit your current rights or which may be to your detriment, we will notify you upon your next visit to the Platform and you will be required to re-confirm your acceptance prior to participating in any Promotional Play. If you do not agree to the amended Sweeps Rules, you may not participate in any Promotional Play.</p>
            <p></p>
            <p></p>
            <p><strong>J.LIMITATIONS OF LIABILITY</strong></p>
            <p>a.Insofar as permitted by law in the respective territories, the Released Parties (as defined above) are not responsible for:</p>
            <p>· any incorrect or inaccurate information, whether caused by a Participant, printing error or by any of the equipment or programming associated with or utilized in the Sweepstakes;</p>
            <p>· technical failures of any kind, including, but not limited to malfunctions, interruptions, or disconnections in phone lines or network hardware or software;</p>
            <p>· unauthorized human intervention in any part of the entry process or the Sweepstakes;</p>
            <p>· technical or human error which may occur in the administration or operation of the Sweepstakes; or</p>
            <p>· any injury or damage to persons or property which may be caused, directly or indirectly, in whole or in part, from Participant’s participation in the Sweepstakes or receipt or use or misuse of any prize.</p>
            <p>b.If for any reason a Participant’s Sweeps Coins or winning play is confirmed to have been erroneously deleted, lost, or otherwise destroyed or corrupted, Participant’s sole remedy is replacement by Sponsor/Promotor of an equivalent amount of Sweeps Coins.</p>
            <p></p>
            <p></p>
            <p><strong>K.OTHER</strong></p>
            <p>a.Under no circumstances will any Participant be permitted to obtain awards for, and Participant hereby waives all rights to claim, punitive, incidental or consequential damages, or any other damages, including attorneys’ fees, other than Participant’s actual out-of-pocket expenses (i.e., costs associated with participating in this Sweepstakes), and Participant further waives all rights to have damages multiplied or increased. SOME JURISDICTIONS DO NOT ALLOW THE LIMITATIONS OR EXCLUSION OF LIABILITY FOR INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO THE ABOVE MAY NOT APPLY TO YOU. </p>
            <p>b.All issues and questions concerning the construction, validity, interpretation and enforceability of these Sweeps Rules, or the rights and obligations of the Participant and Sponsor/Promoter in connection with the Sweepstakes, are governed by, and construed in accordance with the application of the laws of applicable jurisdiction.</p>
        </div>
        <Footer></Footer>
    </div>
  </template>
  
  <script>
  import Header from '@/components/header.vue'
  import Footer from '@/components/footer.vue'
  export default {
    name: "SweepsRules",
    components: {
        Header, Footer
    }
  }
  </script>
  
  <style lang="scss" scoped>
  .pric {
    width: 100%;
    padding: .4688rem 2.3438rem;
    background-color: #1a1a1a !important;
  
    .title {
        color: #fff;
        font-size: 30px;
        margin: 0 0 50px;
    }
  
    p {
        padding: 0 0 15px;
        font-size: 16px;
        color: #c9c6c6;
        line-height: 33px;
  
    }
  
    .email {
        color: #2987d4;
        text-decoration: none;
    }
  }
  </style>