<template>
  <div>
    <Header></Header>
    <div class="pric">
      <h2 class="title">TERMS AND CONDITIONS</h2>
      <p><strong>DATED: 29/3/2024</strong></p>
      <p></p>
      <p></p>
      <p><strong>A.GENERAL</strong></p>
      <p>a .The Game is owned and operated by One Game Dev (“Company”).</p>
      <p>b .These Terms and Conditions form a binding legal agreement between you and us and apply to your use of any of our Games or our Platform in any way, through any electronic device (web, mobile, tablet or any other device).</p>
      <p>c .Please note that these Terms and Conditions contain a provision that waives the right to assert any claims, class or representative claims and requires you to resolve past, present and future disputes between you and us through individual arbitration unless you Opt out within the specified time limit.</p>
      <p>d .The game and platform do not provide real money gambling, and the game does not require real money. Only players in the United States (excluding Idaho and Washington) and Canada (excluding Quebec) are eligible to enter the Sweepstakes.</p>
      <p></p>
      <p></p>
      <p><strong>B.PARTICIPATION</strong></p>
      <p>a .You declare and warrant that:</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;<strong>·</strong> you are over 18 years of age or such higher minimum legal age of majority as stipulated in the jurisdiction of your residence and are, under the laws applicable to you, legally allowed to participate in the Games offered on the Platform;</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;<strong>·</strong> You do not reside in or access the Platform from an excluded territory;</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;<strong>·</strong> you participate in the Games strictly in your personal capacity for recreational and entertainment purposes only;</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;<strong>·</strong> you participate in the Games on your own behalf and not on the behalf of any other person;</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;<strong>·</strong> all information that you provide to us during the term of validity of these Terms and Conditions is true, complete and correct, and you will immediately notify us of any change to such information;</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;<strong>·</strong> you will not be involved in any fraudulent, collusive, fixing or other unlawful activity in relation to your or third parties’ participation in any of the Games and you will not use any software-assisted methods or techniques (including but not limited to bots designed to play automatically) or hardware devices for your participation in any of the Games. We reserve the right to invalidate any participation in the event of such behaviour;</p>
      <p>b .It is a Player’s responsibility to ensure that their Participation is lawful in their jurisdiction. Any person who is knowingly in breach of clause 2, including any attempt to circumvent this restriction, for example, by using a VPN, proxy or similar service that masks or manipulates the identification of your real location, or by otherwise providing false or misleading information regarding your location or place of residence, or by Participating from an Excluded Territory or through a third party or on behalf of a third party located in an Excluded Territory, is in breach of these Terms and Conditions. You may be committing fraud and may be subject to criminal prosecution.</p>
      <p></p>
      <p></p>
      <p><strong>C.LICENCE</strong></p>
      <p>a .Subject to your agreement and continuing compliance with these Terms and Conditions, Company grants you a personal, non-exclusive, non-transferable, non-sublicensable, revocable, limited licence to access and use the Platformand the Content, through a supported Web browser or mobile device, solely for your personal, private entertainment and no other reason.</p>
      <p>b .Company grants you a personal, non-exclusive, nontransferable, non-sublicensable, revocable, limited licence to use the avatars available on the Platform. </p>
      <p>c .These Terms and Conditions do not grant you any right, title or interest in the Platform or Content.</p>
      <p>d .You acknowledge and agree that your licence to use the Platform is limited by these Terms and Conditions and if you do not agree to, or act in contravention of, these Terms and Conditions, your licence to use the Platform (including the Games and Content) may be immediately terminated.</p>
      <p>e .Where the Platform or any Game is deemed to be illegal under the laws of the jurisdiction in which you reside or are situated, you will not be granted any licence to, and must refrain from accessing, the Platform or relevant Game.</p>
      <p></p>
      <p></p>
      <p><strong>D.ACCOUNT</strong></p>
      <p>a .You are allowed to have only one Customer Account, including any Inactive Account on the Platform. If you attempt to open more than one Customer Account, all accounts you have opened or try to open may be suspended or closed.</p>
      <p>b .You must notify us immediately if you notice that you have more than one registered Customer Account, whether active or not on any one Platform. If you want to change your email, address, or last name, please do not create a new customer account.</p>
      <p>c .You are required to keep your registration details up to date at all times. If you change your address, email, phone number or any other contact details or personal information contact Customer Support via this form and choose ‘Request Support’ from the drop down menu in order to update your details. The name that you provide to us at registration must be identical to that listed on your government issued identification.Security and Responsibility of Your Customer Account</p>
      <p>d .It is your sole and exclusive responsibility to ensure that your Customer Account login details and any Payment Mediums are kept secure and are only accessible by you. You accept full responsibility for any unauthorised use of your Customer Account and any activity linked to your Customer Account, including by a minor (which in all events is prohibited).</p>
      <p>e .You must not share your Customer Account or password with another person, let anyone else access or use your Customer Account or do any other thing that may jeopardise the security of your Customer Account.</p>
      <p>f .If you become aware of, or reasonably suspect that security in your Customer Account has been compromised, including loss, theft or unauthorised disclosure of your password and Customer Account details, you must notify us immediately.</p>
      <p>g .You are solely responsible for anything that happens through your Customer Account, whether or not you undertook those actions. You acknowledge that your Customer Account may be terminated if someone else uses it and engages in any activity that breaches these Terms and Conditions or is otherwise illegal.</p>
      <p>h .We are not responsible for any abuse or misuse of your Customer Account by third parties due to your disclosure of your login details to any third party, whether such disclosure is intentional or accidental, active or passive.</p>
      <p>i .You will not, directly or indirectly:</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;<strong>·</strong> hack into any part of the Games or Platform through password mining, phishing, or any other means;</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;<strong>·</strong> attempt to modify, reverse engineer, or reverse-assemble any part of the Games or Platform;</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;<strong>·</strong> knowingly introduce viruses, Trojans, worms, logic bombs, spyware, malware, or other similar material;</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;<strong>·</strong> circumvent the structure, presentation or navigational function of any Game so as to obtain information that Company has chosen not to make publicly available on the Platform; or</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;<strong>·</strong> engage in any form of cheating or collusion.</p>
      <p></p>
      <p></p>
      <p><strong>E.INTELLECTUAL PROPERTY</strong></p>
      <p>a .The computer software, the computer graphics, the Platform and the user interface that we make available to you is owned by, or licensed to, Company or its associates and protected by relevant intellectual property laws. You may only use the software for your own personal, recreational uses in accordance with all rules, terms and conditions we have established (including these Terms and Conditions and the Sweeps Rules) and in accordance with all applicable laws, rules and regulations.</p>
      <p>b .You acknowledge that Company is the proprietor or authorized licensee of all intellectual property in relation to any Content.</p>
      <p>c .Your use of the Games and Platform does not provide you with any intellectual property rights in the Content, Games or Platform.</p>
      <p>d .You grant us, and represent and warrant that you have the right to grant us, an irrevocable, perpetual, worldwide, nonexclusive, royalty-free licence to use in whatever way we see fit, any information, images, videos, comments, messages, music or profiles you publish or upload to any website or social media page controlled and operated by Company.</p>
      <p>e .You must not reproduce or modify the Content in any way, including by removing any copyright or trade mark notice.</p>
      <p>f .All trade marks and logos displayed in the Games and Platform are the property of their respective owners and are protected by applicable trade mark and copyright laws.</p>
      <p></p>
      <p></p>
      <p><strong>F.DISRUPTIONS</strong></p>
      <p>a .The Platform is provided on an “as is” basis and to the fullest extent permitted by law, we make no warranty or representation, whether express or implied, in relation to the satisfactory quality, fitness for purpose, completeness or accuracy of the Platform (including the Games and Content).</p>
      <p>b .Company is not liable for any downtime, server disruptions, lagging, or any technical or political disturbance to Game play, nor attempts by you to Participate by methods, means or ways not intended by us.</p>
      <p>c .Company accepts no liability for any damages or losses which are deemed or alleged to have arisen out of or in connection with any Platform or its Content including, without limitation, delays or interruptions in operation or transmission, loss or corruption of data, communication or lines failure, any person’s misuse of a Platform or its Content or any errors or omissions in 8.4. In the event of a Platform system malfunction all Game play on that Platform is void.</p>
      <p>d .Company reserves the right to suspend, modify, remove or add Content to the Platform at its sole discretion with immediate effect and without notice to you. We will not be liable to you for any loss suffered as a result of any changes made or for any modification or suspension of or discontinuance of the Platform (including any Game thereon) and you will have no claims against Company in such regard.</p>
      <p>e .We may temporarily suspend the whole or any part of the Platform for any reason at our sole discretion. We may, but will not be obliged to, give you as much notice as is reasonably practicable of such suspension. We will restore the Platform, as soon as is reasonably practicable, after such temporary suspension.</p>
      <p>f .We Both Agree to Arbitrate. By agreeing to these Terms and Conditions, and to the extent permitted by applicable law, both you and Company agree to resolve any Disputes – including any Dispute concerning the enforceability, validity, scope or severability of this agreement to arbitrate  through final and binding arbitration.</p>
      <p>g .YOU AGREE TO INDEMNIFY AND HOLD HARMLESS US AND OUR AFFILIATES, AND OUR RESPECTIVE PARTNERS, DIRECTORS, OFFICERS, EMPLOYEES, SHAREHOLDERS, SUBCONTRACTORS, LICENSORS, SUPPLIERS AND AGENTS AGAINST ANY AND ALL COSTS, EXPENSES, LIABILITIES AND DAMAGES (WHETHER DIRECT, INDIRECT, SPECIAL, CONSEQUENTIAL, EXEMPLARY OR PUNITIVE OR OTHER) ARISING FROM ANY PARTICIPATION BY YOU, INCLUDING WITHOUT LIMITATION.</p>
      <p>h .TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, UNDER NO CIRCUMSTANCES WHATEVER WILL WE OR OUR AFFILIATES, OR OUR RESPECTIVE PARTNERS, OFFICERS, DIRECTORS, EMPLOYEES, SHAREHOLDERS, AGENTS, LICENSORS, SUBCONTRACTORS AND SUPPLIERS, BE RESPONSIBLE OR LIABLE TO YOU OR TO ANY OTHER ENTITY, EVEN IF ADVISED OF THE POSSIBILITY OF SUCH DAMAGES, UNDER ANY LEGAL THEORY, WHETHER CONTRACT, TORT OR OTHERWISE, FOR ANY INDIRECT, INCIDENTAL , CONSEQUENTIAL, SPECIAL, EXEMPLARY, OR PUNITIVE DAMAGES, INCLUDING ANY LOST PROFITS AND LOST BUSINESS OPPORTUNITIES, BUSINESS INTERRUPTION, LOST REVENUE, INCOME, GOODWILL, USE OF DATA OR OTHER INTANGIBLE LOSSES, IN EACH CASE THAT RESULTS FROM OR RELATESIN ANY MANNER TO YOUR PARTICIPATION OR ANY OTHER ACT OR OMISSION BY US.</p>
      <p></p>
      <p></p>
      <p><strong>G.MISCELLANEOUS</strong></p>
      <p>a .These Terms and Conditions constitute the entire agreement between you and us with respect to your Participation and, save in the case of fraud, supersede all prior or contemporaneous communications and proposals, whether electronic, oral or written, between you and us with respect to your Participation.</p>
      <p>b .You consent to receive marketing communications from Company in respect of its offerings by way of email, post, SMS and telephone notifications, any of which you may unsubscribe from at any. </p>
      <p>c .Company reserves the right to amend these Terms and Conditions, or to implement or amend any procedures, at any time. Any amendments will be published on the Platform and such changes will be binding and effective immediately.</p>
      <p>d .Whenever we amend these Terms and Conditions in a way that would limit your current rights or which may be to your detriment, we will notify you upon your next visit to the Platform and you will be required to re-confirm your acceptance prior to playing any Games. If you do not agree to the amended Terms and Conditions, you must stop using the Platform.</p>
      <p>e .Company will not be liable or responsible for any failure to perform, or delay in performance of, any of our obligations under these Terms and Conditions that is caused by events outside of our reasonable control.</p>
      <p>f .If any of the Terms and Conditions are determined by any competent authority to be invalid, unlawful or unenforceable to any extent, such term, condition or provision will, to that extent, be severed from these Terms and Conditions. All remaining terms, conditions and provisions will continue to be valid to the fullest extent permitted by law. In such cases, the part deemed invalid or unenforceable will be amended in a manner consistent with the applicable law to reflect, as closely as possible, the original import of the invalid or unenforceable provision.</p>
      <p>g .We consider these Terms and Conditions to be open and fair.</p>
      <p>h .The Terms and Conditions prevail over any communication via email or chat.</p>
      <p>i .All correspondence between you and us may be recorded.</p>
      <p>j .These Terms and Conditions are personal to you, and are not assignable, transferable or sub-licensable by you except with our prior written consent. We reserve the right to assign, transfer or delegate any of our rights and obligations hereunder to any third party without notice to you.</p>
      <p>k .These Terms and Conditions may be published in several languages for information purposes and ease of access by players but will all reflect the same principles. It is only the English version that is the legal basis of the relationship between you and us and in case of any discrepancy between a non-English version and the English version of these Terms and Conditions, the English version will prevail.</p>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Header from '@/components/header.vue'
import Footer from '@/components/footer.vue'
export default {
  name: "TermsPage",
  components: {
    Header, Footer
  }
}
</script>

<style lang="scss" scoped>
.pric {
  width: 100%;
  padding: .4688rem 2.3438rem;
  background-color: #1a1a1a !important;

  .title {
    color: #fff;
    font-size: 30px;
  }

  p {
    padding: 0 0 15px;
    font-size: 16px;
    color: #c9c6c6;
    line-height: 33px;

  }

  .email {
    color: #2987d4;
    text-decoration: none;
  }
}
</style>