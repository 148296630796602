<template>
  <div>
    <Header message="/game"></Header>
    <div class="container">
      <div class="bannerbox">
        <!-- 轮播图-游戏展示区域 -->
        <div class="featured"  data-wow-duration="2s" style="display: flex;">
          <h3 style="font-size: .25rem; color: #fff;">Featured Game</h3>
          <a href=""><span>View all</span><i style="display: block; border-radius: 100; color: #Ed9A21;"
              class="el-icon-right" /></a>
        </div>
        <div class="carousel  wow animate__animated animate__zoomInUp" data-wow-offset="300" data-wow-delay="2s" style="position: relative; width: 7.8125rem; margin:auto; padding: .3646rem 0;">
          <el-carousel :interval="0" arrow="always" height="3.6458rem">
            <el-carousel-item v-for="item in images" :key="item.url">
              <div class="banner_box">
                <div class="show_game">
                  <div class="show_game_up">
                    <div class="show_game_up_left">
                      <img :src="item.logo" alt="">
                    </div>
                    <div class="show_game_up_right">
                      <h3 class="show_game_up_right_title">{{ item.name }}</h3>
                      <p class="show_game_up_right_p">
                        <span class="show_game_up_right_p_span">Space Shooting</span>
                        <i class="iconfont icon-icon-user"></i>
                        <span>100.000.000+</span>
                      </p>
                    </div>
                  </div>
                  <p class="show_game_p1">{{ item.gameText }}</p>
                  <p class="show_game_p2">More..</p>
                </div>
                <div class="banner_box_right">
                  <img src="../../assets/images/game_store_ggplay.webp" alt="">
                  <img src="../../assets/images/game_store_aphone.webp" alt="">
                </div>
              </div>
              <img :src="item.url" class="bannerimg" style="width: 100%; height: 100%; border-radius: .0521rem; opacity: .5; object-fit: cover" />
            </el-carousel-item>
          </el-carousel>
        </div>
      </div>
      <!-- 游戏展示区域 -->
      <div class="showgame wow animate__animated animate__zoomIn">
          <div class="showgame_tit ">
            <div class="showgame_tit_left">
              <h2>Play our hit games</h2>
              <p>Discover the awesome games we built with our passion. We are constantly making our games so you will be entertained for your lifetime!</p>
            </div>
            <div class="show_game_tit_right">
              <div class="data_left">
                <img class="data_left_img" src="@/assets/images/home_i1.png" />
                <div class="data_left_number">
                  <p>{{ '150+' }}</p>
                  <h3>games</h3>
                </div>
              </div>
            </div>
          </div>

          <div class="showgame_box">
            <div class="game_item" v-for="(item,index) in images2" :key="index">
              <img class="game_item_bgimg" :src="item.url" alt="">
              <p class="game_item_name">{{ item.name }}</p>
              <div class="game_item_hover">
                <img class="game_item_hoverimg1" :src="item.logo" alt="">
                <p class="game_item_hoverp">{{ item.type }}</p>
                <a href=""><img class="game_item_hoverimg2"  src="../../assets/images/game_store_ggplay.webp" alt=""></a>
              </div>
            </div>
          </div>
        </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Header from '@/components/header'
import Footer from '@/components/footer'
export default {
    name:'GAME',
    components: {
    Header,
    Footer,
  },
  data() {
    return {
      images: [
        { url: require("@/assets/images/gp3.png"),name:'Chilli Fiesta',number:'',logo:require("@/assets/images/logo_3.png"),gameText:"The hottest new slots app that brings the sizzling excitement of slot games right to your fingertips! Immerse yourself in a vibrant festival of flaming wins and spicy jackpots. With Chilli Fiesta, every spin is a fiesta, featuring colorful graphics, engaging themes, and thrilling bonuses. Whether you're a seasoned player or a newcomer, Chilli Fiesta offers an array of games that cater to every taste and skill level. Ready to turn up the heat? Download Chilli Fiesta now and start spinning to ignite your luck and sweep up fiery rewards!" },
        { url: require("@/assets/images/Game_Zombie Zest Veggie Warfare_1024_640_1.png"),name:'Zombie Zest: Veggie Warfare',number:'',logo:require("@/assets/images/Game-icon-Zombie Zest Veggie Warfare_179_182_1.png"),gameText:'In this action-packed game, immerse yourself in a world where veggies fight for survival against hordes of ravenous zombies. Strategize, upgrade your arsenal, and unleash epic vegetable-powered attacks to fend off the undead menace. With captivating gameplay and vibrant graphics, "Zombie Zest: Veggie Warfare" promises an addictive gaming experience that will keep you on the edge of your seat!' },
        { url: require("@/assets/images/gp2.png"),name:'Heroic Havens: Tower Defense',number:'',logo:require("@/assets/images/gp2_icon.png"),gameText:'Embark on an exciting tower defense journey in this captivating and engaging game.'},
      ],
      images2: [
        { url: require("@/assets/images/Game_Poke Genie Land_bg512_512_1.png"),type:"Action",name:'Poke Genie Land',logo:require("@/assets/images/Game__Poke Genie Land_icon50_50_1.png"),},
        { url: require("@/assets/images/Game_Wonder Homescape Deluxe_bg512_512_2.png"),type:"Adventure",name:'Wonder Homescape',logo:require("@/assets/images/Game_Wonder Homescape Deluxe_icon50_50_2.png"),},
        { url: require("@/assets/images/Game_Magic Doggy 2_bg512_512_3.png"),type:"Adventure",name:'Magic Doggy 2',logo:require("@/assets/images/Game_Magic Doggy 2_50_50_3.png"),},
        { url: require("@/assets/images/Game_Roaming Wonderland Deluxe_bg512_512_4.png"),type:"Adventure",name:'Roaming Wonderland Deluxe',logo:require("@/assets/images/Game_Roaming Wonderland Deluxe_icon50_50_4.png"),},
        { url: require("@/assets/images/Game_Alien ChewingGum_bg512_512_5.png"),type:"Action",name:'Alien Chewing Gum',logo:require("@/assets/images/Game_Alien ChewingGum_icon50_50_5.png"),},
        { url: require("@/assets/images/Game_Royal Billiards_bg512_512_6.png"),type:"Adventure",name:'Royal Billiards',logo:require("@/assets/images/Game_Royal Billiards_icon50_50_6.png"),},

      ],
    }
  },
}
</script>

<style lang="scss" scoped>
@import url(@/fontCss/fontCss.css);

.banner_box{
  position: absolute;
  top: 50%;
  left: .9375rem;
  width: 5.9375rem;
  display: flex;
  .banner_box_right{
    position: absolute;
    z-index: 9;
    top: .2344rem;
    left: 3.7292rem;
    img{
      cursor: pointer;
      width: 1.1354rem;
      height: .3333rem;
    }
  }
}
.banner_logo{
  width: .6667rem;
  height: .6667rem;
}
.show_game{
  height: 2.5521rem;
  width: 1.7188rem;
  position: absolute;
  z-index: 9;
  top: -1.25rem;
  left: .7292rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end
}
.show_game_p1{
  font-size: .0729rem;
  color: #B3B3B3;
  margin: .0521rem 0;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp:3;
  -webkit-box-orient:vertical;
}
.show_game_p2{
  font-size: .0833rem;
  color: #fff;
}
.show_game_up{
  display: flex;
  justify-content: space-evenly;
  padding-top: .1042rem;
}
.show_game_up_right{
  padding: 0 0 0 .0521rem;
  height: .3646rem;
}
.show_game_up_right_title{
  color: #fff;
  font-size: .1563rem;
  line-height: .1354rem;
  padding: 0 0 .0521rem;
  width: 1.25rem;
  line-height: .1823rem;
}
.show_game_up_right_p>i{
  margin: 0 0 0 .1563rem;
  color: #ffffdd9e;
}
.show_game_up_right_p_span{
  font-size: .0729rem;
  color: #B3B3B3;
}
.show_game_up_right_p:last-child{
  font-size: .0729rem;
  color: #B3B3B3;
}
.show_game_up_left{
  img{
    width: .6667rem;
    height: .6667rem;
    border-radius: .0521rem;
  }
}
.showgame{
  width: 5.9375rem;
  margin: 0 auto;
}



.showgame_tit{
  display: flex;
  justify-content: space-evenly;
  margin-bottom: .1563rem;
  .showgame_tit_left{
    h2{
      font-size: .2083rem;
      color: #FB9C2C;
    }
    p{
      font-size: .0833rem;
      color: #B3B3B3;
      width: 3.125rem;
    }
  }
}
.data_left,.data_center,.data_right{
  display: flex;
  align-items: center
}
.data_left_img{
  width: .2083rem;
  height: .3333rem;
}
.data_left_number{
  margin-left: .1042rem;
  p{
    font-size: .2708rem;
    line-height: .3802rem;
    color: #fff;
    font-weight: bold;
  }
  h3{
    color: #B3B3B3;
    font-size: .1667rem;
    line-height: .2188rem;
  }
}
.showgame_box{
  width: 5.9375rem;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  border: .0052rem solid #FB9C2C;
  border-radius: .0521rem;
}
.game_item{
  width: 1.3281rem;
  height: 1.8906rem;
  border-radius: .0521rem;
  margin: .0521rem .0729rem;
  background-color: #000;
  text-align: center;
  position: relative;
  .game_item_bgimg{
    border-radius: .0521rem;
    width: 100%;
    height: auto;
    opacity: .6;
  }
  .game_item_name{
    margin-top: .1563rem;
    cursor: pointer;
    font-size: .1042rem;
    color: #fff;
    transition: color .3s ease;
  }
  .game_item_name:hover{
    color: #FB9C2C;
  }
  .game_item_hover{
    position: absolute;
    top: .5729rem;
    left: .3906rem;
  }
  .game_item_hoverimg1{
    width: .2604rem;
    height: .2604rem;
    border-radius: .026rem;
  }
  .game_item_hoverimg2{
    width: .5208rem;
    height: auto;
  }
  .game_item_hoverp{
    color: #fff;
    margin: .0521rem 0;
  }
}

</style>