<template>
  <div class="home_body">
    <Header message="/home"></Header>
    <div class="container">

      <div class="game_studio">
        <div class="studio_left wow animate__animated animate__zoomIn">
          <div class="studio_name"><strong>{{ studio_name }}</strong></div>
          <h3 class="studio_declaration">{{ studio_declaration }}</h3>
          <p class="brief_introduction">{{ brief_introduction }}</p>
          <div class="studio_button wow animate__animated animate__rollIn">
            <strong>{{ studio_button }}</strong>
          </div>
        </div>
        <div class="studio_right wow animate__animated animate__fadeInLeft">
          <img src="@/assets/images/abi-game-studio.png" alt="">
        </div>
      </div>

      <div class="data_display">
        <div class="data_left wow animate__animated animate__zoomInRight">
          <img class="data_left_img" src="@/assets/images/home_i1.png" />
          <div class="data_left_number">
            <p>{{ data_display1 }}</p>
            <h3>games</h3>
          </div>
        </div>
        <div class="data_center wow animate__animated animate__zoomInUp">
          <img class="data_left_img" src="@/assets/images/home_i2.png" />
          <div class="data_left_number">
            <p>{{ data_display2 }}</p>
            <h3>downloads</h3>
          </div>
        </div>
        <div class="data_right wow animate__animated animate__zoomInLeft">
          <img class="data_left_img" src="@/assets/images/home_i3.png" />
          <div class="data_left_number">
            <p>{{ data_display3 }}</p>
            <h3>daily actives</h3>
          </div>
        </div>
      </div>

      <!-- <div class="awards">
        <div class="awards_left">
          <div class="awards_left_up">
            <img class="awards_left_img" src="../../assets/images/abi-ranking.png" alt="">
          </div>
          <div class="awards_left_down"></div>
        </div>
        <div class="awards_right">
          <h2 class="awards_right_h2">{{ awards_right1 }}</h2>
          <span class="awards_right_span1">{{ awards_right2 }}</span>
          <span class="awards_right_span2">{{ awards_right3 }}</span>
          <div class="studio_button">
            <strong>{{ studio_button }}</strong>
          </div>
        </div>
      </div> -->

      <div class="games">
        <h2 class="games_h2">GAMES</h2>
        <h3 class="games_h3">{{ games_h3 }}</h3>
        <div class="display_game">
          <div class="display_game_left wow animate__animated animate__bounceInUp">
            <img class="display_game_left_img1" src="@/assets/images/card-1.png" alt="">
            <img class="display_game_left_img2" src="@/assets/images/galaxy.jpg" alt="">
            <div class="show_game wow animate__animated animate__flipIn" >
              <div class="show_game_up">
                <div class="show_game_up_left wow animate__animated animate__fadeIn" >
                  <img src="../../assets/images/game_1.jpg" alt="">
                </div>
                <div class="show_game_up_right">
                  <h3 class="show_game_up_right_title">{{ show_game_up_right_title }}</h3>
                  <p class="show_game_up_right_p">
                    <span class="show_game_up_right_p_span">Space Shooting</span>
                    <i class="iconfont icon-icon-user"></i>
                    <span>100.000.000+</span>
                  </p>
                </div>
              </div>
              <p class="show_game_p1">{{ show_game_p1 }}</p>
              <p class="show_game_p2">More..</p>
            </div>
          </div>
          <div class="display_game_right">
            <div class="display_game_card_banner wow animate__animated animate__rollIn">
              <el-carousel :interval="5000" type="card" height="2.0833rem">
                <el-carousel-item v-for="(item,index) in bannerGamesImg" :key="index">
                  <img class="banner_img" :src="item.url" alt="">
                  <div class="show_game_up">
                    <div class="show_game_up_left">
                      <img :src="item.logo" alt="">
                    </div>
                    <div class="show_game_up_right game_banner_hover">
                      <h3 class="show_game_up_right_title">{{ item.title }}</h3>
                      <p class="show_game_up_right_p">
                        <span class="show_game_up_right_p_span">Happy Game</span>
                        <i class="iconfont icon-icon-user"></i>
                        <span>90.000.000+</span>
                      </p>
                    </div>
                  </div>
                  <div style="position: relative;">
                    <div class="display_game_store">
                      <img class="display_game_store_img1" src="@/assets/images/game_store_aphone.webp" alt="">
                      <img class="display_game_store_img2" src="@/assets/images/game_store_ggplay.webp" alt="">
                    </div>
                    <div class="display_game_card_banner_button">PLAY</div>
                  </div>
                </el-carousel-item>
              </el-carousel>
            </div>
          </div>
        </div>
      </div>

      <div class="publish  wow animate__animated animate__zoomIn" data-wow-offset="10" data-wow-duration="2s">
        <div class="studio_left">
          <div class="studio_name"><strong>{{ publish_name }}</strong></div>
          <h3 class="studio_declaration">{{ publish_declaration }}</h3>
          <p class="brief_introduction">{{ publish_introduction }}</p>
          <div class="studio_button">
            <strong>{{ publish_button }}</strong>
          </div>
        </div>
        <div class="studio_right">
          <img src="@/assets/images/mascot.png" alt="">
        </div>
      </div>
    </div>

    <div class="career  wow animate__animated animate__zoomInDown">
      <div class="studio_right">
        <img src="@/assets/images/career.jpg" alt="">
      </div>
      <div class="studio_left">
        <div class="studio_name"><strong>{{ career_name }}</strong></div>
        <h3 class="studio_declaration">{{ career_declaration }}</h3>
        <p class="brief_introduction">{{ career_introduction }}</p>
        <div class="studio_button">
          <strong>{{ career_button }}</strong>
        </div>
      </div>
    </div>

    <!-- 无限滚动卡片区域 -->
    <div class="scroll_bg">
      <div class="scroll_bg_title">TOP PARTNERS</div>
      <div style="padding-bottom: .5208rem;">
        <vue-seamless-scroll :data="images2" :class-option="optionLeft" class="seamless-warp2">
          <ul class="item">
            <li v-for="(item,index) in images2" :key="index">
              <img class="scrollimg" :src="item.url" alt="">
            </li>
          </ul>
        </vue-seamless-scroll>
      </div>
    </div>
    

    <Footer></Footer>
  </div>
</template>

<script>
import Header from '@/components/header'
import Footer from '@/components/footer'
export default {
  name: 'HOME',
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      studio_name: 'ONE GAME DEV',
      studio_declaration: 'We are One Game Dev',
      brief_introduction: "One Game Dev is proud to stand as a unified team of more than 100 employees. With over a decade of experience in the mobile gaming industry, we've successfully published over 150 game titles on Google Play and the App Store. Among our accomplishments, several of our games have reached the pinnacle, securing top 10 rankings in various categories.",
      studio_button:'Find Out More',
      data_display1:'110+',
      data_display2:'2B+',
      data_display3:'11M+',
      awards_right1:'#2 ASIA ',
      awards_right2:'Top Publisher Awards 2022 by AppAnnie',
      awards_right3:'In 2021, Onesoft achieved our goal and was ranked #2 on the Top ANZSEA Publisher Awards by AppAnnie.',
      games_h3:'We unleash great games',
      show_game_p1:'Embark on an adrenaline-fueled adventure with "Zombie Zest: Veggie Warfare"! In this action-packed game, immerse yourself in a world where veggies fight for survival against hordes of ravenous zombies. Strategize, upgrade your arsenal, and unleash epic vegetable-powered attacks to fend off the undead menace. With captivating gameplay and vibrant graphics, "Zombie Zest: Veggie Warfare" promises an addictive gaming experience that will keep you on the edge of your seat!',
      show_game_up_right_title:'Zombie Zest: Veggie Warfare',
      publish_name:'PUBLISHING',
      publish_declaration:"We're leader in publishing games",
      publish_introduction:'Fostering long-term and sustainable relationships, our Publishing, Marketing, and Optimization experts are here to help you create the next hits.',
      publish_button:'Submit your game',
      career_name:'CAREER',
      career_declaration:'Join One Game Dev Today!',
      career_introduction:"Seize the opportunity to collaborate with creative, like-minded individuals, and together, let's shape the gaming landscape and make an impact on the world.",
      career_button:'JOIN US',

      bannerGamesImg:[
        { url: require("@/assets/images/banner_game1.jpg"),logo: require("@/assets/images/banner_game1_logo.jpg"),title:'Magic Doggy' },
        { url: require("@/assets/images/banner_game2.jpg"),logo: require("@/assets/images/banner_game2_logo.jpg"),title:'Roaming Wonderland' },
        { url: require("@/assets/images/banner_game3.jpg"),logo: require("@/assets/images/banner_game3_logo.jpg"),title:'Snakey Candy Road' },
        { url: require("@/assets/images/banner_game4.jpg"),logo: require("@/assets/images/banner_game4_logo.jpg"),title:'Fighting! Planting!' },
      ],
      images2: [
        { url: require("@/assets/topPartners/pangle.png") },
        { url: require("@/assets/topPartners/unity.png") },
        { url: require("@/assets/topPartners/vungle.png") },
        { url: require("@/assets/topPartners/tapjoy.png") },
        { url: require("@/assets/topPartners/ggads.png") },
        { url: require("@/assets/topPartners/ggadmob.png") },
        { url: require("@/assets/topPartners/unity.png") },
      ]
    }
  },
  computed: {
    optionLeft() {
      return {
        direction: 2,
        limitMoveNum: 2
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import url(@/fontCss/fontCss.css);
.game_studio {
  width: 5.9375rem;
  height: 2.5rem;
  margin-top: .2604rem !important;
  padding: .5208rem 0;
  margin: 0 auto;
  display: flex;
  justify-content: space-around;
}

.studio_left>.studio_name{
  font-size: .0833rem;
  line-height: .1354rem;
  color: #FB9C2C;
}
.studio_left>.studio_declaration{
  font-size: .1875rem;
  line-height: .3229rem;
  color: #fff;
  padding: 0 0 .0521rem;
}
.studio_left>.brief_introduction{
  width: 2.8021rem;
  font-size: .0833rem;
  font-family: '楷书';
  line-height: .1563rem;
  color: #B3B3B3;
}
.studio_button{
  width: 1.3021rem;
  height: .3125rem;
  background-image: url(@/assets/images/button.png);
  background-size: cover;
  margin-top: .1563rem;
  text-align: center;
  line-height: .3125rem;
  font-size: .0938rem;
  color: #8F1209;
}
.studio_left,.studio_right{
  width: 2.8073rem;
  height: 2.2031rem;
}

.data_display{
  width: 5.8854rem;
  margin: .7813rem auto ;
  display: flex;
  justify-content: space-evenly;
}
.data_left,.data_center,.data_right{
  display: flex;
  align-items: center
}
.data_left_img{
  width: .2083rem;
  height: .3333rem;
}
.data_left_number{
  margin-left: .1042rem;
  p{
    font-size: .2708rem;
    line-height: .3802rem;
    color: #fff;
    font-weight: bold;
  }
  h3{
    color: #B3B3B3;
    font-size: .1667rem;
    line-height: .2188rem;
  }
}

// .awards{
//   display: flex;
//   justify-content: center;
// }
// .awards_right{
//   width: 2.8125rem;
//   display: flex;
//   flex-direction: column;
//   justify-content: space-around;
// }
// .awards_left{
//   text-align: center;
//   margin-right: .2604rem;
// }
// .awards_left_img{
//   width: 1.5208rem;
//   height: 1.2969rem;
// }
// .awards_left_down{
//   width: 2.8125rem;
//   height: .9635rem;
//   background-color: #B3B3B3;
// }
// .awards_right_h2{
//   font-size: .0833rem;
//   color: #FB9C2C;
//   line-height: .1354rem;
// }
// .awards_right_span1{
//   font-size: .1875rem;
//   font-weight: bold;
//   color: #fff;
// }
// .awards_right_span2{
//   font-size: .0833rem;
//   line-height: .1563rem;
//   color: #B3B3B3;
// }

.games{
  width: 5.9375rem;
  margin: .5208rem auto;
}
.games_h2{
  font-size: .0833rem;
  line-height: .1354rem;
  color: #FB9C2C;
}
.games_h3{
  font-size: .1875rem;
  line-height: .3229rem;
  color: #fff;
}
.display_game{
  display: flex;
}
.display_game_left{
  width: 2.1094rem;
  height: 2.6823rem;
  position: relative;
  top: 0;
  left: 0;
  padding: .1563rem;
}
.display_game_left_img1{
  position: absolute;
  top: 0;
  left: 0;
  width: 2.0833rem;
  height: 2.6823rem;
  margin: .026rem;
  z-index: 1;
  cursor: pointer;
  opacity: 0.5;
  transition: opacity 0.3s ease;
}
.display_game_left_img1:hover{
  opacity: 1;
}
.display_game_left_img2{
  width: 2.0573rem;
  height: 2.6302rem;
  margin: .026rem;
  position: absolute;
  top: .0208rem;
  border-radius: 25px 0 25px 0;
  left: .0156rem;
  cursor: pointer;
}
.show_game{
  height: 2.5521rem;
  width: 1.7188rem;
  position: absolute;
  z-index: 0;
  top: 0;
  left: .2083rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end
}
.show_game_p1{
  font-size: .0729rem;
  color: #B3B3B3;
  margin: .0521rem 0;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp:7;
  -webkit-box-orient:vertical;
}
.show_game_p2{
  font-size: .0833rem;
  color: #fff;
}
.show_game_up{
  display: flex;
  justify-content: space-evenly;
  padding-top: .1042rem;
}
.show_game_up_right{
  padding: 0 0 0 .0521rem;
  height: .3646rem;
}
.show_game_up_right_title{
  color: #fff;
  font-size: .0833rem;
  line-height: .1354rem;
  padding: 0 0 .0521rem;
}
.show_game_up_right_p>i{
  margin: 0 0 0 .1563rem;
  color: #ffffdd9e;
}
.show_game_up_right_p_span{
  font-size: .0729rem;
  color: #B3B3B3;
}
.show_game_up_right_p:last-child{
  font-size: .0729rem;
  color: #B3B3B3;
}
.show_game_up_left{
  img{
    width: .3906rem;
    height: .3906rem;
    border-radius: .0521rem;
  }
}
// 轮播卡片样式区域
.el-carousel__item {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: .0781rem;
  background-color: #666 !important;
  transition: background-color 1s ease;
  border-radius: .0781rem;
  .banner_img {
    width: 1.6146rem;
    height: 1.0104rem;
    margin: 0 auto;
    border-radius: .026rem;
  }
}
.el-carousel__item:hover{
  background-color: #FB9C2C !important;
  transition: background-color 1s ease;
  .display_game_card_banner_button{
    background-color: #FB9C2C;
    color: #FB9C2C;
  }
  .display_game_store{
    transition: display 1s ease;
    display: block;
  }
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n+1) {
  background-color: #d3dce6;
}
.display_game_card_banner{
  margin-left: .1042rem;
  width: 3.6458rem;
  height: 2.1875rem;
  padding-top: .2604rem;
}

.display_game_card_banner_button{
  font-size: .1563rem;
  color: #fff;
  text-align: center;
  background-color: #2c2c2c;
  transition: background-color 1s ease;
  line-height: .3385rem;
}
.display_game_store{
  display: flex;
}
.display_game_store_img1{
  position: absolute;
  top: -0.0521rem;
  left: .0521rem;
  width: .7604rem;
  height: .224rem;
}
.display_game_store{
  display: none;
  transition: display 1s ease;
}
.display_game_store_img2{
  position: absolute;
  top: -0.0521rem;
  left: .9896rem;
  width: .7604rem;
  height: .224rem;
}
.publish,.career,.game_studio{
  width: 5.9375rem;
  height: 2.5rem;
  margin: .5208rem auto;
  display: flex;
  justify-content: space-around;
  .studio_right{
  margin-right: .2604rem;
  img{
    width: 2.8073rem;
    height: 2.3281rem;
  }
}
}
.career>
/** 滚动区域样式 */
.scroll_bg_title{
  color: #ED9A21;
  font-size: .0833rem;
  padding: .1563rem 0;
}
.seamless-warp2 {
  overflow: hidden;
  height: .625rem;
  width: 7.8125rem;
  margin: .2604rem auto;
  padding: .2083rem;
  background-color: #000;
  border-radius: .0521rem;
  border: .0052rem solid #ED9A21;

  ul.item {
    width: 7.0313rem;
    list-style: none;
    li {
      float: left;
      margin-right: .5208rem;
    }
  }
}
.scrollimg{
  width: .4167rem;
  height: auto ;
}
.scroll_bg{
  width: 100vw;
  height: 2.0833rem;
  padding: .2344rem 0;
  background-color: #1a1a1a;
  text-align: center;
}
@media(max-width: 1000){
  .game_studio{
    flex-wrap: wrap;
  }
}
</style>