<template>
    <div>
        <Header></Header>
        <div class="pric">
            <h2 class="title">ResponsibleGameplayPolicy</h2>
            <p><strong>DATED: 29/3/2024</strong></p>
            <p>One Game Dev is committed to the protection of our players and promoting responsible social gameplay as a policy of customer care and social responsibility</p>
            <p></p>
            <p></p>
            <p><strong>SUPPORT ORGANISATIONS</strong></p>
            <p>If your gameplay may have had, or is at risk of having, a negative impact on your mental health, finances or relationships with friends or family, we encourage you to get in touch with the following help and support organizations:</p>
            <p>(a) Counseling Gaming Addicts Anonymous (GAA) is a fellowship of people who support each other in recovering from the problems resulting from excessive game playing.</p>
            <p>(b) Credit Counseling</p>
            <p>(i) Financial Counseling Association of America (FCAA) is a professional association of financial counseling agencies that assist consumers with financial counseling services of all kinds, as well as debt management plans for the repayment of unsecured debts.</p>
            <p>(ii) National Foundation for Credit Counseling (NFCC) is one of the oldest networks of non-profit financial counseling agencies. The NFCC helps people to defeat their debt and look forward with confidence.</p>
            <p>(c) Please note that these organizations are independent support services and are NOT in any way affiliated with Game. They do NOT provide customer support or dispute resolution services.</p>
            <p><strong>TIPS</strong></p>
            <p>(a) Avoid gameplay while upset or emotional.</p>
            <p>(b) Take frequent breaks during your gameplay sessions</p>
            <p>(c) Avoid gameplay while intoxicated.</p>
            <p>(d) Avoid canceling redemptions.</p>
            <p>(e) Remember that gameplay is only a form of entertainment, it should not be seen as a source of income or an escape from reality.</p>
            <p>(f) Purchase only with money that you can afford to spend.</p>
            <p>(g) Set a budget and don ’t go over it</p>
            <p>(h) Set a time limit before playing.</p>
            <p>(i) Understand how games work before playing and remember that the results are random.</p>
            <p>(j) Never let gameplay affect your employment, relationships, health or commitments.</p>
            <p></p>
            <p></p>
            <p><strong>PROTECTION</strong></p>
            <p>(a) Make sure that the decision to play on our Platform is your own personal choice and responsibility.</p>
            <p>(b) We do not recommend playing on our Platform if you:</p>
            <p>(i) are being treated or are in recovery for an addiction/dependency</p>
            <p>(ii) are under the influence of alcohol or any other substance</p>
            <p>(iii) are currently experiencing financial difficulty or a traumatic life event</p>
            <p>(iv) do not understand how to play the Games</p>
            <p>(v) have any mental health concerns, cognitive impairment or brain injury.</p>
            <p></p>
            <p></p>
            <p><strong>INTRODUCTION</strong></p>
            <p>This Responsible Gameplay Policy (RG Policy) describes the control tools, information and resources available to registered players on Game.</p>
            <p>This RG Policy forms part of the Game Terms and Conditions. Terms which are defined in the Terms and Conditions have the same meaning in this RG Policy.</p>
            <p>We may update the RG Policy at any time. Any amendments will be published on the Platform and such changes will be binding and effective immediately.</p>
            <p>Whenever we amend this RG Policy in a way that would limit your current rights or which may be detrimental, we will notify you upon your next visit to the Platform and you will be required to re-confirm your acceptance prior to playing any Games. If you do not agree to the amended RG Policy, you must stop using the Platform.</p>
            <p></p>
            <p></p>
            <p><strong>Game GRG Program</strong></p>
            <p>The Game Responsible Gameplay Program (GRG Program) is centered around our guiding principles of providing our customers with control tools, information and help resources needed to:</p>
            <p>Make an informed decision in gameplay</p>
            <p>Prevent problem gameplay from occurring on our site.</p>
            <p>The GRG Program is designed to support the needs of individuals at any stage of the customer journey and any level of gameplay. To do this, the GRG Program offers a range of player education, control tools and resources for professional help when needed.</p>
            <p>We also understands that it is a shared responsibility to achieve a fun and affordable gameplay environment and that it is ultimately an individual ’s choice to play. We do not provide counseling services nor do we police customer behavior. Instead, we focus on providing control tools and informing, educating and supporting informed decisions.</p>
            <p>We have well-trained staff available to assist you in relation to your gameplay.</p>
            <p>Our staff are encouraged and empowered to provide information and offer control tools proactively.</p>
            <p></p>
            <p></p>
            <p><strong>RSG PLAY CONTROL TOOLS</strong></p>
            <p>Activity Reminders</p>
            <p>Game assists your play by providing an Activity Reminder every hour.</p>
            <p>The Activity Reminder:</p>
            <p>(i) suspends play and indicates how long you have been playing</p>
            <p>(ii) displays your play history since logging in</p>
            <p>(iii) allows you to end the gameplay session or continue playing.</p>
            <p>Account History</p>
            <p>Gameplay History Shows the result of each spin/hand played.</p>
            <p>Transaction History Shows your purchase and redemption history.</p>
        </div>
        <Footer></Footer>
    </div>
  </template>
  
  <script>
  import Header from '@/components/header.vue'
  import Footer from '@/components/footer.vue'
  export default {
    name: "ResponsibleGameplayPolicy",
    components: {
        Header, Footer
    }
  }
  </script>
  
  <style lang="scss" scoped>
  .pric {
    width: 100%;
    padding: .4688rem 2.3438rem;
    background-color: #1a1a1a !important;
  
    .title {
        color: #fff;
        font-size: 30px;
        margin: 0 0 50px;
    }
  
    p {
        padding: 0 0 15px;
        font-size: 16px;
        color: #c9c6c6;
        line-height: 33px;
  
    }
  
    .email {
        color: #2987d4;
        text-decoration: none;
    }
  }
  </style>